import { addStopToVehicle } from './vehicle.js';

let currentVehicle = null;

function setupAutocomplete(inputElement) {
    let timeoutId;
    const autocompleteResults = document.createElement('div');
    autocompleteResults.className = 'autocomplete-results';
    inputElement.parentNode.insertBefore(autocompleteResults, inputElement.nextSibling);

    inputElement.addEventListener('input', function() {
        clearTimeout(timeoutId);
        const searchTerm = this.value;

        if (searchTerm.length < 3) {
            autocompleteResults.innerHTML = '';
            return;
        }

        timeoutId = setTimeout(() => {
            // Add viewbox parameter for South Africa and country code
            const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchTerm)}&viewbox=16.3,-34.8,32.9,-22.1&bounded=1&countrycodes=za`;

            fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                    autocompleteResults.innerHTML = '';
                    data.slice(0, 5).forEach(result => {
                        const div = document.createElement('div');
                        div.className = 'autocomplete-item';
                        div.textContent = result.display_name;
                        div.addEventListener('click', function() {
                            inputElement.value = result.display_name;
                            autocompleteResults.innerHTML = '';
                            addStopToVehicle(result, currentVehicle);
                        });
                        autocompleteResults.appendChild(div);
                    });
                })
                .catch(error => {
                    console.error('Error fetching autocomplete results:', error);
                });
        }, 300);
    });

    // Close autocomplete when clicking outside
    document.addEventListener('click', function(e) {
        if (e.target !== inputElement && !autocompleteResults.contains(e.target)) {
            autocompleteResults.innerHTML = '';
        }
    });
}

function searchAndAddStop(searchTerm, vehicle) {
    currentVehicle = vehicle;
    // Add viewbox parameter for South Africa and country code
    const apiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(searchTerm)}&viewbox=16.3,-34.8,32.9,-22.1&bounded=1&countrycodes=za`;

    fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
            if (data.length > 0) {
                const result = data[0];
                addStopToVehicle(result, vehicle);
            } else {
                console.log('No results found for the search term in South Africa');
                // You might want to show a message to the user here
            }
        })
        .catch(error => {
            console.error('Error searching for location:', error);
        });
}

export { 
    setupAutocomplete, 
    searchAndAddStop 
};
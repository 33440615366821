import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, onSnapshot } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDgQhgJqr00aBv8UHCEpGiP4MA4ZvgW9fE",
    authDomain: "composed-hold-345108.firebaseapp.com",
    projectId: "composed-hold-345108",
    storageBucket: "composed-hold-345108.appspot.com",
    messagingSenderId: "687259342828",
    appId: "1:687259342828:web:3bb47d8d1c74e6253a92c9",
    measurementId: "G-PW0MHHZJYC"
};

let app;
let db;
let auth;

export function initFirebase() {
    console.log('Initializing Firebase...');
    console.log('Firebase config:', firebaseConfig);
    
    try {
        app = initializeApp(firebaseConfig);
        console.log('Firebase app initialized:', app);
        
        db = getFirestore(app);
        console.log('Firestore instance created:', db);
        
        auth = getAuth(app);
        console.log('Firebase Auth initialized:', auth);
        
        console.log('Firebase initialization complete');
        return { app, db, auth };
    } catch (error) {
        console.error('Error initializing Firebase:', error);
        throw error;
    }
}

export function getDb() {
    if (!db) {
        console.error('Firestore instance not initialized. Call initFirebase() first.');
        throw new Error('Firebase has not been initialized. Call initFirebase() first.');
    }
    console.log('Returning Firestore instance:', db);
    return db;
}

export function getFirebaseAuth() {
    if (!auth) {
        console.error('Firebase Auth not initialized. Call initFirebase() first.');
        throw new Error('Firebase Auth has not been initialized. Call initFirebase() first.');
    }
    return auth;
}

// Add a new function to check if Firebase is initialized
export function isFirebaseInitialized() {
    console.log('Checking Firebase initialization status');
    console.log('App:', app);
    console.log('DB:', db);
    return !!app && !!db;
}

// Log when the module is loaded
console.log('firebase-init.js module loaded');

export async function logFirebaseSchema() {
    console.log('Attempting to log Firebase schema...');
    const db = getDb();
    const schema = {};

    try {
        // Predefined list of collections to check
        const collectionsToCheck = ['vehicle', 'charger', 'user'];

        for (const collectionName of collectionsToCheck) {
            const collectionRef = collection(db, collectionName);
            const snapshot = await getDocs(collectionRef);

            if (!snapshot.empty) {
                const doc = snapshot.docs[0];
                const data = doc.data();
                schema[collectionName] = {};
                for (const field in data) {
                    schema[collectionName][field] = typeof data[field];
                }
            } else {
                console.log(`Collection ${collectionName} is empty or does not exist`);
            }
        }

        console.log('Firebase Schema:', JSON.stringify(schema, null, 2));
    } catch (error) {
        console.error('Error fetching Firebase schema:', error);
    }
}
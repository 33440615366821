console.log('app.js is being executed');

// Use dynamic imports instead
import { initMap, cleanupMapListeners, resetMap } from './map.js';
import { setupSidebar, setupMapDetailsListeners } from './sidebar.js';
import { setupVehicleListener, cleanupVehicleListener } from './vehicle.js';
import { setupChargerListener, fetchChargers, cleanupChargerListener } from './charger.js'; // Update this line
//import { initializeApp } from '../node_modules/firebase/app';
//import { getAuth } from '../node_modules/firebase/auth';
//import { getFirestore } from '../node_modules/firebase/firestore';
import { initAuth, checkAuth } from './auth.js';
import { setupUserProfileButton } from './userProfile.js';
import { recreateSidebarElements } from './sidebar.js';
import { recreateClusterGroups } from './map.js';
import posthog from 'posthog-js';

let isInitialized = false;

posthog.init('phc_GLrbMPR1CLX03QXRlqTkVV3JCw5Z7cb6iDQN20hoZdi',
    {
        api_host: 'https://eu.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    },
    (err) => {
        if (err) {
            console.error('PostHog initialization error:', err);
        } else {
            console.log('PostHog initialized successfully');
        }
    }
);

console.log('PostHog initialized');

async function initApp() {
    if (isInitialized) {
        // Clean up existing listeners and reset state
        cleanupVehicleListener();
        cleanupChargerListener();
        cleanupMapListeners();
        resetAppState();
        return; // Exit here to avoid recursive calls
    }
    posthog.capture('my event', { property: 'value' })
    initAuth();
    checkAuth(async (isAuthenticated) => {
        if (isAuthenticated) {
            await initMap();
            setupSidebar();
            setupMapDetailsListeners(); // Move this line here
            setupVehicleListener();
            setupChargerListener();
            setupUserProfileButton();
            await fetchChargers();
            addRefreshButton();
            console.log('App initialized');
            posthog.capture('app_initialized', { status: 'success' });
            isInitialized = true;
        } else {
            console.log('User not authenticated');
        }
    });
}

function addRefreshButton() {
    const refreshButton = document.querySelector('.leaflet-control-refresh a');
    if (refreshButton) {
        refreshButton.addEventListener('click', function(e) {
            e.preventDefault();
            refreshApplication();
        });
    }
}

function refreshApplication() {
    console.log('Refreshing application...');
    initApp();
    recreateClusterGroups(); // Add this line
}

function resetAppState() {
    console.log('Resetting app state...');
    
    // Reset map
    resetMap();
    
    // Clear and recreate sidebar elements
    recreateSidebarElements();
    
    // Reset global state variables
    isInitialized = false;
    
    // Clean up existing listeners
    cleanupVehicleListener();
    cleanupChargerListener();
    cleanupMapListeners();
    
    // Re-initialize the app
    initApp();
}

// Modify your existing DOMContentLoaded event listener
document.addEventListener('DOMContentLoaded', function() {
    initApp();
    posthog.capture('dom_content_loaded', { status: 'success' });
});

export { refreshApplication };

// Global error handler
window.addEventListener('error', (event) => {
    console.error('Global error handler:', event.message);
});

// Global unhandled rejection handler
window.addEventListener('unhandledrejection', (event) => {
    console.error('Global unhandled rejection:', event.reason);
});
import { createPopupContent } from './utils.js';
import { chargers } from './charger.js';
import { showVehicleDetails } from './vehicle.js';
import { showChargerDetails } from './charger.js'; // Make sure this import exists

let map = null;
let markers = {};
let vehicleClusterGroup;
let chargerClusterGroup;

function initMap() {
    console.log('Initializing map...');
    map = L.map('map').setView([0, 0], 2);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    vehicleClusterGroup = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
            return L.divIcon({
                html: `<div style="background-color: blue; color: white; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;">${cluster.getChildCount()}</div>`,
                className: 'vehicle-cluster-icon',
                iconSize: L.point(30, 30),
                iconAnchor: L.point(15, 30)
            });
        },
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true
    });
    chargerClusterGroup = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
            return L.divIcon({
                html: `<div style="background-color: white; color: #666; border: 2px solid #666; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;">${cluster.getChildCount()}</div>`,
                className: 'charger-cluster-icon',
                iconSize: L.point(30, 30),
                iconAnchor: L.point(15, 30)
            });
        },
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true
    });

    map.addLayer(vehicleClusterGroup);
    map.addLayer(chargerClusterGroup);

    addLegend(); // Add this line to call the new function
}

const vehicleIcon = L.icon({
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41], // This is correct (center-bottom of the icon)
    popupAnchor: [1, -34],
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    shadowSize: [41, 41]
});

const chargerIcon = L.divIcon({
    html: '<div class="charger-icon"><i class="fas fa-charging-station"></i></div>',
    className: 'custom-div-icon',
    iconSize: [36, 36], // Adjusted to match the CSS
    iconAnchor: [18, 36], // Center-bottom of the icon
    popupAnchor: [0, -36]
});

function addOrUpdateMarker(markerData) {
    const { id, type, latitude, longitude, name, status, popupContent, onClick } = markerData;

    if (markers[id]) {
        map.removeLayer(markers[id]);
        if (type === 'vehicle') {
            vehicleClusterGroup.removeLayer(markers[id]);
        } else if (type === 'charger') {
            chargerClusterGroup.removeLayer(markers[id]);
        }
    }

    const marker = L.marker([latitude, longitude], {
        icon: getMarkerIcon(type, status)
    });

    marker.bindPopup(popupContent);

    if (onClick) {
        marker.on('click', (e) => {
            onClick(e);
            // Remove the marker from the cluster group and add it directly to the map
            if (type === 'vehicle') {
                vehicleClusterGroup.removeLayer(marker);
                map.addLayer(marker);
            }
            // Zoom to the marker
            map.setView([latitude, longitude], 15);
        });
    }

    markers[id] = marker;

    if (type === 'vehicle') {
        vehicleClusterGroup.addLayer(marker);
    } else if (type === 'charger') {
        chargerClusterGroup.addLayer(marker);
    }

    updateMarkerDisplay();
}

function updateSpeedLabel(marker, speed) {
    if (!marker || !marker.getElement()) {
        console.warn('Marker or marker element is undefined');
        return;
    }

    const speedLabel = marker.getElement().querySelector('.speed-label');
    if (!speedLabel) {
        console.warn('Speed label not found for marker');
        return;
    }

    speedLabel.textContent = `${speed} km/h`;
}

function removeMarker(id) {
    const marker = markers[id];
    if (marker) {
        if (marker.options.icon === vehicleIcon) {
            vehicleClusterGroup.removeLayer(marker);
        } else {
            chargerClusterGroup.removeLayer(marker);
        }
        delete markers[id];
    }
}

function addLegend() {
    const legend = L.control({ position: 'bottomright' });

    legend.onAdd = function () {
        const div = L.DomUtil.create('div', 'info legend');
        div.innerHTML = `
            <div><i style="background: blue; width: 18px; height: 18px; display: inline-block; border-radius: 50%;"></i> Vehicles</div>
            <div><i style="background: white; width: 18px; height: 18px; display: inline-block; border-radius: 50%; border: 2px solid #666;"></i> Chargers</div>
        `;
        return div;
    };

    legend.addTo(map);
}

function updateChargerMarkers() {
    chargers.forEach(charger => {
        addOrUpdateMarker({
            id: charger.id,
            type: 'charger',
            latitude: parseFloat(charger.coordinates.latitude),
            longitude: parseFloat(charger.coordinates.longitude),
            name: charger.name,
            status: charger.status
        });
    });
}

function updateMarkerDisplay() {
    const showVehicles = document.getElementById('showVehicles')?.checked || false;
    const showChargers = document.getElementById('showChargers')?.checked || false;

    if (showVehicles) {
        map.addLayer(vehicleClusterGroup);
    } else {
        map.removeLayer(vehicleClusterGroup);
    }

    if (showChargers) {
        map.addLayer(chargerClusterGroup);
    } else {
        map.removeLayer(chargerClusterGroup);
    }
}

function zoomToVehicle(vehicle) {
    if (!map || !vehicle) return;
    
    let latitude, longitude;
    
    if (typeof vehicle.data === 'function') {
        const data = vehicle.data();
        latitude = data.latitude;
        longitude = data.longitude;
    } else {
        latitude = vehicle.latitude;
        longitude = vehicle.longitude;
    }

    if (latitude && longitude) {
        map.setView([latitude, longitude], 15); // Zoom level 15, adjust as needed
    } else {
        console.error('Invalid vehicle location data');
    }
}

function zoomToCharger(charger) {
    if (!map || !charger || !charger.coordinates) {
        console.error('Invalid map or charger data');
        return;
    }

    let latitude = parseFloat(charger.coordinates.latitude);
    let longitude = parseFloat(charger.coordinates.longitude);

    if (isNaN(latitude) || isNaN(longitude)) {
        console.error('Invalid coordinates:', charger.coordinates);
        return;
    }

    map.setView([latitude, longitude], 15); // Zoom level 15, adjust as needed
    console.log(`Zoomed to charger: ${charger.name} at ${latitude}, ${longitude}`);
}

function cleanupMapListeners() {
    if (map) {
        // Remove all event listeners from the map
        map.off();
        // Remove the map instance
        map.remove();
        map = null;
    }
    // Clear all markers
    markers = {};
    // Clear marker clusters if they exist
    if (vehicleClusterGroup) {
        vehicleClusterGroup.clearLayers();
    }
    if (chargerClusterGroup) {
        chargerClusterGroup.clearLayers();
    }
}

function resetMap() {
    if (map) {
        map.remove();
        map = null;
    }
}

function recreateClusterGroups() {
    if (vehicleClusterGroup) {
        map.removeLayer(vehicleClusterGroup);
        vehicleClusterGroup.clearLayers();
    }
    if (chargerClusterGroup) {
        map.removeLayer(chargerClusterGroup);
        chargerClusterGroup.clearLayers();
    }

    vehicleClusterGroup = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
            return L.divIcon({
                html: `<div style="background-color: blue; color: white; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;">${cluster.getChildCount()}</div>`,
                className: 'vehicle-cluster-icon',
                iconSize: L.point(30, 30),
                iconAnchor: L.point(15, 30)
            });
        },
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true
    });
    chargerClusterGroup = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
            return L.divIcon({
                html: `<div style="background-color: white; color: #666; border: 2px solid #666; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;">${cluster.getChildCount()}</div>`,
                className: 'charger-cluster-icon',
                iconSize: L.point(30, 30),
                iconAnchor: L.point(15, 30)
            });
        },
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true
    });

    map.addLayer(vehicleClusterGroup);
    map.addLayer(chargerClusterGroup);

    // Re-add existing markers to the new cluster groups
    Object.values(markers).forEach(marker => {
        if (marker.options.icon === vehicleIcon) {
            vehicleClusterGroup.addLayer(marker);
        } else {
            chargerClusterGroup.addLayer(marker);
        }
    });

    updateMarkerDisplay();
}

export {
    initMap,
    addOrUpdateMarker,
    removeMarker,
    zoomToVehicle,
    zoomToCharger,
    updateMarkerDisplay,
    map,
    markers,
    vehicleClusterGroup,
    chargerClusterGroup,
    cleanupMapListeners,
    resetMap,
    recreateClusterGroups
};

function getMarkerIcon(type, status) {
    if (type === 'vehicle') {
        return L.icon({
            iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41], // This is correct (center-bottom of the icon)
            popupAnchor: [1, -34],
            shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
            shadowSize: [41, 41]
        });
    } else if (type === 'charger') {
        return L.divIcon({
            html: '<div class="charger-icon"><i class="fas fa-charging-station"></i></div>',
            className: 'custom-div-icon',
            iconSize: [36, 36], // Adjusted to match the CSS
            iconAnchor: [18, 36], // Center-bottom of the icon
            popupAnchor: [0, -36]
        });
    }
}

export function zoomToMarker(id) {
    const marker = markers[id];
    if (marker) {
        const latLng = marker.getLatLng();
        map.setView(latLng, 15);
        
        // Remove the marker from the cluster group and add it directly to the map
        if (marker.options.icon === getMarkerIcon('vehicle')) {
            vehicleClusterGroup.removeLayer(marker);
            map.addLayer(marker);
        }
        
        marker.openPopup();
    }
}

import { getDb } from './firebase-init.js';
import { map } from './map.js';
import { chargers } from './charger.js';
import { setupAutocomplete } from './search.js';
import { collection, addDoc, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { estimateSOCRequirement } from './utils.js';
import { getSelectedVehicle } from './vehicle.js';
import { updateMarkerDisplay } from './map.js';

let currentVehicle = null;
let currentRoute = null;
let routeMarkers = [];
let routeLine = null;

let showVehiclesCheckbox, showChargersCheckbox;
let routeSummaryContainer;

export function showRoutePlanner(vehicle) {
    currentVehicle = vehicle;
    const rightSidebar = document.getElementById('right-sidebar');
    rightSidebar.innerHTML = `
        <div id="routePlannerPanel" class="panel">
            <h2>Route Planner for ${vehicle.name || vehicle.id}</h2>
            <div id="routeStops">
                <h3>Stops</h3>
                <ul id="routeStopsList"></ul>
            </div>
            <div class="search-container">
                <div class="search-input-wrapper">
                    <input type="text" id="routeStopSearch" placeholder="Search for a location">
                    <button id="addRouteStop" class="search-button" title="Add Stop">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <div id="searchResults" class="search-results"></div>
            </div>
            <div id="mapOptions">
                <h3>Map Options</h3>
                <div class="map-option">
                    <input type="checkbox" id="showVehiclesRoute">
                    <label for="showVehiclesRoute">Show Vehicles</label>
                </div>
                <div class="map-option">
                    <input type="checkbox" id="showChargersRoute">
                    <label for="showChargersRoute">Show Chargers</label>
                </div>
            </div>
            <button id="saveRoute" class="btn">Save Route</button>
            <button id="clearRoute" class="btn">Clear Route</button>
            <div id="savedRoutes">
                <h3>Saved Routes</h3>
                <ul id="savedRoutesList"></ul>
            </div>
        </div>
    `;

    // Create route summary container
    routeSummaryContainer = document.createElement('div');
    routeSummaryContainer.id = 'routeSummaryContainer';
    routeSummaryContainer.className = 'route-summary-container';
    document.getElementById('left-sidebar').appendChild(routeSummaryContainer);

    setupAutocomplete(document.getElementById('routeStopSearch'));
    document.getElementById('addRouteStop').addEventListener('click', searchLocation);
    document.getElementById('saveRoute').addEventListener('click', saveRoute);
    document.getElementById('clearRoute').addEventListener('click', clearRoute);

    // Set up the new checkboxes and link them to the existing ones
    const showVehiclesCheckbox = document.getElementById('showVehiclesRoute');
    const showChargersCheckbox = document.getElementById('showChargersRoute');
    const existingShowVehicles = document.getElementById('showVehicles');
    const existingShowChargers = document.getElementById('showChargers');

    // Set initial state if the elements exist
    if (showVehiclesCheckbox && existingShowVehicles) {
        showVehiclesCheckbox.checked = existingShowVehicles.checked;
    }
    if (showChargersCheckbox && existingShowChargers) {
        showChargersCheckbox.checked = existingShowChargers.checked;
    }

    // Add event listeners to sync the checkboxes and update the map
    if (showVehiclesCheckbox && existingShowVehicles) {
        showVehiclesCheckbox.addEventListener('change', () => {
            console.log('Route planner vehicle checkbox changed');
            existingShowVehicles.checked = showVehiclesCheckbox.checked;
            updateMarkerDisplay();
        });
        existingShowVehicles.addEventListener('change', () => {
            showVehiclesCheckbox.checked = existingShowVehicles.checked;
            updateMarkerDisplay();
        });
    }

    if (showChargersCheckbox && existingShowChargers) {
        showChargersCheckbox.addEventListener('change', () => {
            console.log('Route planner charger checkbox changed');
            existingShowChargers.checked = showChargersCheckbox.checked;
            updateMarkerDisplay();
        });
        existingShowChargers.addEventListener('change', () => {
            showChargersCheckbox.checked = existingShowChargers.checked;
            updateMarkerDisplay();
        });
    }

    showVehicleAndChargers();
    loadSavedRoutes();
}

function searchLocation() {
    const stopInput = document.getElementById('routeStopSearch');
    const stopName = stopInput.value.trim();
    const searchResults = document.getElementById('searchResults');
    searchResults.innerHTML = '';

    if (stopName) {
        searchResults.innerHTML = '<div class="loading">Searching...</div>';
        const geocodeUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(stopName)}`;
        fetch(geocodeUrl)
            .then(response => response.json())
            .then(data => {
                searchResults.innerHTML = '';
                if (data.length > 0) {
                    data.slice(0, 5).forEach(location => {
                        const resultItem = document.createElement('div');
                        resultItem.className = 'search-result-item';
                        resultItem.textContent = location.display_name;
                        resultItem.addEventListener('click', () => addRouteStop(location));
                        searchResults.appendChild(resultItem);
                    });
                } else {
                    searchResults.innerHTML = '<div class="no-results">No results found</div>';
                }
            })
            .catch(error => {
                console.error('Error searching location:', error);
                searchResults.innerHTML = '<div class="error">Error searching location. Please try again.</div>';
            });
    }
}

function addRouteStop(location) {
    const li = document.createElement('li');
    li.textContent = location.display_name;
    li.dataset.lat = location.lat;
    li.dataset.lon = location.lon;
    const removeButton = document.createElement('button');
    removeButton.innerHTML = '&times;';
    removeButton.className = 'remove-stop';
    removeButton.onclick = () => removeRouteStop(li);
    li.appendChild(removeButton);
    document.getElementById('routeStopsList').appendChild(li);
    document.getElementById('routeStopSearch').value = '';
    document.getElementById('searchResults').innerHTML = '';
    updateRouteOnMap();
}

function removeRouteStop(listItem) {
    listItem.remove();
    updateRouteOnMap();
}

let routeControl = null;

function updateRouteOnMap() {
    console.error('updateRouteOnMap function called');

    if (routeControl) {
        console.error('Removing existing routeControl');
        map.removeControl(routeControl);
    }

    const stops = Array.from(document.getElementById('routeStopsList').children);
    console.error('Stops found:', stops.length);

    const waypoints = stops.map(stop => {
        const lat = parseFloat(stop.dataset.lat);
        const lon = parseFloat(stop.dataset.lon);
        console.error(`Waypoint: ${lat}, ${lon}`);
        return L.latLng(lat, lon);
    });

    console.error('Waypoints:', waypoints);

    if (waypoints.length > 1) {
        console.error('Creating new routeControl');
        routeControl = L.Routing.control({
            waypoints: waypoints,
            routeWhileDragging: false,
            show: false,
            addWaypoints: false,
            lineOptions: {
                styles: [{ color: '#0000FF', opacity: 0.6, weight: 4 }]
            },
            router: L.Routing.osrmv1({
                serviceUrl: 'https://router.project-osrm.org/route/v1'
            })
        }).addTo(map);

        routeControl.on('routesfound', function(e) {
            console.error('Routes found event triggered');
            const routes = e.routes;
            const summary = routes[0].summary;
            const totalDistance = (summary.totalDistance / 1000).toFixed(2);
            console.error('Total distance:', totalDistance);
            const selectedVehicle = getSelectedVehicle();
            
            if (selectedVehicle) {
                console.error('Selected vehicle:', selectedVehicle);
                const result = estimateSOCRequirement(parseFloat(totalDistance), selectedVehicle);
                console.error('SOC estimation result:', result);
                
                if (result && typeof result.socPercentage === 'string' && typeof result.energyRequired === 'string') {
                    const socPercentage = result.socPercentage;
                    const energyRequired = result.energyRequired;
                    console.error('SOC requirement:', socPercentage, 'Energy required:', energyRequired);
                    
                    try {
                        updateRouteSummary(totalDistance, energyRequired, socPercentage, selectedVehicle.soc);
                    } catch (error) {
                        console.error('Error in updateRouteSummary:', error);
                        // Fallback to showing route summary without energy information
                        updateRouteSummary(totalDistance);
                    }
                } else {
                    console.error('Invalid result from estimateSOCRequirement:', result);
                    updateRouteSummary(totalDistance);
                }
            } else {
                console.error('No vehicle selected');
                updateRouteSummary(totalDistance);
            }
        });

        console.error('Calling routeControl.route()');
        routeControl.route();
    } else {
        console.error('Not enough waypoints to create a route');
    }

    if (waypoints.length > 0) {
        console.error('Fitting map to bounds');
        const bounds = L.latLngBounds(waypoints);
        map.fitBounds(bounds);
    }
}

function updateRouteSummary(totalDistance, energyRequired, socPercentage, currentSoc) {
    const stops = Array.from(document.getElementById('routeStopsList').children);
    let summaryHtml = '<h3>Route Summary</h3>';

    stops.forEach((stop, index) => {
        summaryHtml += `<div class="stop-summary">
            <h4>Stop ${index + 1}</h4>
            <p>${stop.textContent.replace(' ×', '')}</p>
        </div>`;
    });

    summaryHtml += `<div class="total-summary">
        <h4>Total</h4>
        <p><strong>Distance:</strong> ${totalDistance} km</p>`;

    if (energyRequired !== undefined && socPercentage !== undefined) {
        summaryHtml += `<p><strong>Energy Required:</strong> ${energyRequired} kWh</p>
            <p><strong>SOC Required:</strong> ${socPercentage}%</p>`;
    }

    if (currentSoc !== undefined) {
        summaryHtml += `<p><strong>Current SOC:</strong> ${currentSoc}%</p>`;
    }

    summaryHtml += '</div>';

    routeSummaryContainer.innerHTML = summaryHtml;
}

function getRandomLatLng() {
    const bounds = map.getBounds();
    const lat = bounds.getSouth() + Math.random() * (bounds.getNorth() - bounds.getSouth());
    const lng = bounds.getWest() + Math.random() * (bounds.getEast() - bounds.getWest());
    return [lat, lng];
}

async function saveRoute() {
    const stops = Array.from(document.getElementById('routeStopsList').children).map(li => li.textContent.replace(' ×', ''));
    if (stops.length < 2) {
        alert('A route must have at least two stops.');
        return;
    }

    const db = getDb();
    const routesRef = collection(db, 'routes');
    try {
        const docRef = await addDoc(routesRef, {
            vehicleId: currentVehicle.id,
            stops: stops,
            createdAt: new Date()
        });
        console.log('Route saved with ID: ', docRef.id);
        alert('Route saved successfully!');
        loadSavedRoutes();
    } catch (error) {
        console.error('Error saving route: ', error);
        alert('Error saving route. Please try again.');
    }
}

async function loadSavedRoutes() {
    const db = getDb();
    const routesRef = collection(db, 'routes');
    const q = query(routesRef, where('vehicleId', '==', currentVehicle.id));
    
    try {
        const querySnapshot = await getDocs(q);
        const savedRoutesList = document.getElementById('savedRoutesList');
        savedRoutesList.innerHTML = '';
        querySnapshot.forEach((doc) => {
            const route = doc.data();
            const li = document.createElement('li');
            li.textContent = `Route ${doc.id} (${route.stops.length} stops)`;
            li.innerHTML += ' <button class="load-route">Load</button> <button class="delete-route">Delete</button>';
            li.querySelector('.load-route').addEventListener('click', () => loadRoute(doc.id, route));
            li.querySelector('.delete-route').addEventListener('click', () => deleteRoute(doc.id));
            savedRoutesList.appendChild(li);
        });
    } catch (error) {
        console.error('Error loading saved routes: ', error);
    }
}

function loadRoute(routeId, route) {
    const stopsList = document.getElementById('routeStopsList');
    stopsList.innerHTML = '';
    route.stops.forEach(stop => {
        const li = document.createElement('li');
        li.textContent = stop;
        li.innerHTML += ' <button class="remove-stop">×</button>';
        li.querySelector('.remove-stop').addEventListener('click', () => removeRouteStop(li));
        stopsList.appendChild(li);
    });
    updateRouteOnMap();
}

async function deleteRoute(routeId) {
    const db = getDb();
    try {
        await deleteDoc(doc(db, 'routes', routeId));
        console.log('Route deleted: ', routeId);
        loadSavedRoutes();
    } catch (error) {
        console.error('Error deleting route: ', error);
        alert('Error deleting route. Please try again.');
    }
}

function clearRoute() {
    document.getElementById('routeStopsList').innerHTML = '';
    updateRouteOnMap();
}

function showVehicleAndChargers() {
    // Hide all markers
    Object.values(markers).forEach(marker => {
        map.removeLayer(marker);
    });

    // Show only the current vehicle
    if (currentVehicle) {
        const vehicleMarker = markers[currentVehicle.id];
        if (vehicleMarker) {
            map.addLayer(vehicleMarker);
        }
    }

    // Show all chargers
    chargers.forEach(charger => {
        const chargerMarker = markers[charger.id];
        if (chargerMarker) {
            map.addLayer(chargerMarker);
        }
    });
}

// Add this function to your routePlanner.js file
function sendRouteSummaryToDriverFromPlanner() {
    const routeInfo = {
        distance: totalDistance, // Make sure these variables are defined in your route planner
        time: estimatedTime,
        socRequired: estimatedSOCRequired,
        currentSoc: currentVehicle.soc
    };

    const routeSummary = routeStops.map((stop, index) => {
        return {
            name: stop.name,
            latitude: stop.latitude,
            longitude: stop.longitude,
            chargerType: stop.chargerType,
            chargerStatus: stop.chargerStatus,
            availableConnectors: stop.availableConnectors
        };
    });

    sendRouteSummaryToDriver(currentVehicle.phoneNumber, routeInfo, routeSummary);
}
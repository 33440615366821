export function sendRouteToDriver(phoneNumber, routeInfo) {
    console.log(`Preparing to send route to driver at ${phoneNumber}:`, routeInfo);

    const message = `
        New route assigned:
        Destination: ${routeInfo.destination}
        Distance: ${routeInfo.distance} km
        Estimated time: ${routeInfo.time} minutes
        Estimated SOC required: ${routeInfo.socRequired}%
    `;

    // Create options for sending the route
    const options = [
        { id: 'whatsapp', name: 'WhatsApp', icon: 'fab fa-whatsapp' },
        { id: 'app', name: 'Driver App', icon: 'fas fa-mobile-alt' },
        { id: 'sms', name: 'SMS', icon: 'fas fa-sms' }
    ];

    // Create a modal dialog for selecting the send method
    const modal = document.createElement('div');
    modal.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    `;

    const modalContent = document.createElement('div');
    modalContent.style.cssText = `
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        max-width: 300px;
        width: 100%;
    `;

    modalContent.innerHTML = `
        <h3>Send Route to Driver</h3>
        <p>Choose a method to send the route:</p>
        ${options.map(option => `
            <button class="send-option" data-id="${option.id}">
                <i class="${option.icon}"></i> ${option.name}
            </button>
        `).join('')}
    `;

    modal.appendChild(modalContent);
    document.body.appendChild(modal);

    // Add event listeners to the buttons
    modal.querySelectorAll('.send-option').forEach(button => {
        button.addEventListener('click', () => {
            const method = button.getAttribute('data-id');
            sendRoute(method, phoneNumber, message);
            document.body.removeChild(modal);
        });
    });
}

function sendRoute(method, phoneNumber, message) {
    switch (method) {
        case 'whatsapp':
            sendWhatsApp(phoneNumber, message);
            break;
        case 'app':
            sendToApp(phoneNumber, message);
            break;
        case 'sms':
            sendSMS(phoneNumber, message);
            break;
        default:
            console.error('Invalid send method');
    }
}

function sendWhatsApp(phoneNumber, message) {
    console.log(`Sending WhatsApp message to ${phoneNumber}`);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
}

function sendToApp(phoneNumber, message) {
    // In a real application, you would send a push notification or update the driver's app
    console.log(`Sending route to driver app for ${phoneNumber}`);
    alert(`Route sent to driver app (${phoneNumber}):\n\n${message}`);
}

function sendSMS(phoneNumber, message) {
    // In a real application, you would integrate with an SMS gateway
    console.log(`Sending SMS to ${phoneNumber}`);
    alert(`SMS sent to driver (${phoneNumber}):\n\n${message}`);
}

export function sendRouteSummaryToDriver(phoneNumber, routeInfo, routeSummary) {
    console.log(`Preparing to send route summary to driver at ${phoneNumber}:`, routeInfo);

    let message = `New route assigned:\n\n`;

    // Add overall route information
    message += `Total Distance: ${routeInfo.distance} km\n`;
    message += `Estimated Time: ${routeInfo.time} minutes\n`;
    message += `Estimated SOC Required: ${routeInfo.socRequired}%\n`;
    message += `Current Vehicle SOC: ${routeInfo.currentSoc}%\n\n`;

    // Add detailed stop information
    message += `Route Summary:\n`;
    routeSummary.forEach((stop, index) => {
        message += `\nStop ${index + 1}: ${stop.name}\n`;
        // Create Google Maps link
        const googleMapsLink = `https://www.google.com/maps/dir/?api=1&destination=${stop.latitude},${stop.longitude}`;
        message += `Directions: ${googleMapsLink}\n`;
        if (stop.chargerType) {
            message += `Charger Type: ${stop.chargerType}\n`;
            message += `Charger Status: ${stop.chargerStatus}\n`;
            message += `Available Connectors: ${stop.availableConnectors}\n`;
        }
    });

    // Send the message via WhatsApp
    sendWhatsApp(phoneNumber, message);
}
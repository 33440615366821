import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { initFirebase, getFirebaseAuth } from './firebase-init.js';

let auth;

export function initAuth() {
    const { auth: firebaseAuth } = initFirebase();
    auth = firebaseAuth;
}

export function showLoginPopup() {
    const loginPopup = document.getElementById('loginPopup');
    loginPopup.style.display = 'flex';
}

export function hideLoginPopup() {
    const loginPopup = document.getElementById('loginPopup');
    loginPopup.style.display = 'none';
}

export async function login(email, password) {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        hideLoginPopup();
        return true;
    } catch (error) {
        console.error('Login error:', error);
        alert('Login failed. Please check your credentials and try again.');
        return false;
    }
}

export async function logout() {
    try {
        await signOut(auth);
        showLoginPopup();
        console.error('User signed out successfully');
    } catch (error) {
        console.error('Logout error:', error);
    }
}

export function checkAuth(callback) {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            callback(true);
        } else {
            showLoginPopup();
            callback(false);
        }
    });
}

// Add this new function to get the current user
export function getCurrentUser() {
    return auth.currentUser;
}
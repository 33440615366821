import { getCurrentUser, logout } from './auth.js';

export function setupUserProfileButton() {
    const userProfileButton = document.getElementById('userProfileButton');
    userProfileButton.addEventListener('click', toggleUserProfilePanel);
}

function toggleUserProfilePanel() {
    const rightSidebar = document.getElementById('right-sidebar');
    const userProfilePanel = document.getElementById('userProfilePanel');

    if (userProfilePanel.style.display === 'none' || userProfilePanel.style.display === '') {
        showUserProfilePanel();
    } else {
        hideUserProfilePanel();
    }
}

function showUserProfilePanel() {
    const rightSidebar = document.getElementById('right-sidebar');
    const userProfilePanel = document.getElementById('userProfilePanel');

    // Hide other panels
    document.getElementById('vehicleListPanel').style.display = 'none';
    document.getElementById('mapDetailsPanel').style.display = 'none';
    document.getElementById('vehicleInfoPanel').style.display = 'none';
    document.getElementById('chargerListPanel').style.display = 'none';
    document.getElementById('chargerDetailsPanel').style.display = 'none';

    // Show right sidebar and user profile panel
    rightSidebar.style.display = 'block';
    userProfilePanel.style.display = 'block';

    // Populate user profile information
    const user = getCurrentUser();
    if (user) {
        userProfilePanel.innerHTML = `
            <h2>User Profile</h2>
            <p><strong>Email:</strong> ${user.email}</p>
            <p><strong>UID:</strong> ${user.uid}</p>
            <button id="logoutButton">Logout</button>
        `;

        document.getElementById('logoutButton').addEventListener('click', handleLogout);
    } else {
        console.error('No user is currently signed in');
        userProfilePanel.innerHTML = '<p>Error: No user is currently signed in</p>';
    }
}

function hideUserProfilePanel() {
    document.getElementById('userProfilePanel').style.display = 'none';
}

async function handleLogout() {
    try {
        await logout();
        hideUserProfilePanel();
    } catch (error) {
        console.error('Error during logout:', error);
    }
}
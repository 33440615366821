function createPopupContent(doc) {
    const data = doc.data ? doc.data() : doc;
    
    if (data.evseId) { // This is a charger
        return `
            <h3>${data.name || `Charger ${data.id}`}</h3>
            <p><strong>Status:</strong> ${data.status || 'Unknown'}</p>
            <p><strong>Type:</strong> ${data.evseId.startsWith('AC') ? 'AC' : 'DC'}</p>
            <p><strong>Available Connectors:</strong> ${data.connectorsAvailable || 0} / ${data.connectorsCount || 0}</p>
        `;
    } else { // This is a vehicle
        const lastUpdated = data.last_updated_timestamp ? new Date(data.last_updated_timestamp).toLocaleString() : 'Unknown';
        return `
            <h3>${data.name || `Vehicle ${doc.id}`}</h3>
            <p><strong>VIN:</strong> ${doc.id}</p>
            <p><strong>Speed:</strong> ${data.speed || 0} km/h</p>
            <p><strong>Last Updated:</strong> ${lastUpdated}</p>
        `;
    }
}

function getStatusClass(status) {
    if (!status) return ''; // Return empty string for null or undefined status
    
    switch (status.toLowerCase()) {
        case 'available':
            return 'status-available';
        case 'occupied':
            return 'status-occupied';
        case 'offline':
            return 'status-offline';
        default:
            return '';
    }
}

function estimateSOCRequirement(distance, vehicle) {
    console.error('Estimating SOC requirement:', distance, vehicle);
    // Assuming the vehicle object has batteryCapacity and averageConsumption properties
    const batteryCapacity = vehicle.batteryCapacity || 52.5; // kWh, default to 52.5 if not provided
    const averageConsumption = vehicle.averageConsumption || 0.1875; // kWh/km, default to 52.5 kWh / 280 km if not provided
    
    const estimatedConsumption = distance * averageConsumption;
    const socRequired = (estimatedConsumption / batteryCapacity) * 100;
    
    const result = {
        socPercentage: Math.min(socRequired, 100).toFixed(2),
        energyRequired: estimatedConsumption.toFixed(2)
    };
    
    console.error('Estimation result:', result);

    return result;
}

export { 
    getStatusClass, 
    createPopupContent,
    estimateSOCRequirement
};

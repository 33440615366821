import { getDb } from './firebase-init.js';
import { addOrUpdateMarker, removeMarker } from './map.js';
import { getStatusClass } from './utils.js';
import { collection, onSnapshot, getDocs } from 'firebase/firestore';
import { map } from './map.js';
import { showRoute } from './route.js'; // Add this import
import { hideVehicleInfo } from './sidebar.js';
import { createPopupContent } from './utils.js';

let chargers = [];
let chargerListener = null;

async function fetchChargers() {
    try {
        const db = getDb();
        const chargersRef = collection(db, 'charger');
        const snapshot = await getDocs(chargersRef);
        console.error('Initial charger data received:');
        snapshot.forEach(doc => {
            const charger = doc.data();
            charger.id = doc.id;
            console.error(JSON.stringify(charger, null, 2));
            chargers.push(charger);
        });
        console.log('Fetched chargers:', chargers); // Add this line for debugging
        updateChargerMarkers(chargers);
        renderChargerList(chargers);
    } catch (error) {
        console.error('Error fetching chargers:', error);
    }
}

function setupChargerListener() {
    const db = getDb();
    const chargersRef = collection(db, 'charger');
    
    chargerListener = onSnapshot(chargersRef, (snapshot) => {
        console.error('Charger data update received:');
        snapshot.docChanges().forEach((change) => {
            if (change.type === "added" || change.type === "modified") {
                const charger = change.doc.data();
                charger.id = change.doc.id;
                console.error(JSON.stringify(charger, null, 2));
                const index = chargers.findIndex(c => c.id === charger.id);
                if (index !== -1) {
                    chargers[index] = charger;
                } else {
                    chargers.push(charger);
                }
                addOrUpdateMarker({
                    id: change.doc.id,
                    type: 'charger',
                    latitude: charger.coordinates.latitude,
                    longitude: charger.coordinates.longitude,
                    name: charger.name,
                    status: charger.status,
                    popupContent: createPopupContent(charger),
                    onClick: () => handleChargerClick(charger)
                });
            }
        });
        renderChargerList(); // Call renderChargerList after updating the chargers array
    }, (error) => {
        console.error('Error in charger listener:', error);
    });
}

function cleanupChargerListener() {
    if (chargerListener) {
        chargerListener();
        chargerListener = null;
    }
    // Reset any charger-related state
    chargers = [];
}

function updateCharger(id, data) {
    const index = chargers.findIndex(charger => charger.id === id);
    if (index !== -1) {
        chargers[index] = { id, ...data };
    } else {
        chargers.push({ id, ...data });
    }
    updateChargerMarker(id, data);
}

function removeCharger(id) {
    chargers = chargers.filter(charger => charger.id !== id);
    removeMarker(id);
}

function updateChargerMarker(id, data) {
    addOrUpdateMarker({
        id: id,
        type: 'charger',
        latitude: parseFloat(data.coordinates.latitude),
        longitude: parseFloat(data.coordinates.longitude),
        name: data.name,
        status: data.status,
        popupContent: createPopupContent(data),
        onClick: () => handleChargerClick(data)
    });
}

// Add this new function
function showTransitionConfirmationPopup(onConfirm) {
    const popup = document.createElement('div');
    popup.className = 'communication-popup';
    popup.innerHTML = `
        <div class="popup-content">
            <h3>Switch to Charger Details?</h3>
            <p>This action will close the vehicle details. How would you like to proceed?</p>
            <div class="option-buttons">
                <button id="confirmSwitch" class="option-button">
                    <i class="fas fa-check"></i>
                    <span>Switch to Charger</span>
                </button>
                <button id="cancelSwitch" class="option-button">
                    <i class="fas fa-times"></i>
                    <span>Stay on Vehicle</span>
                </button>
            </div>
        </div>
    `;
    document.body.appendChild(popup);

    document.getElementById('confirmSwitch').addEventListener('click', () => {
        onConfirm();
        document.body.removeChild(popup);
    });

    document.getElementById('cancelSwitch').addEventListener('click', () => {
        document.body.removeChild(popup);
    });
}

// Modify the existing handleChargerClick function
function handleChargerClick(charger) {
    const vehicleInfoPanel = document.getElementById('vehicleInfoPanel');
    if (vehicleInfoPanel.style.display !== 'none') {
        showTransitionConfirmationPopup(() => {
            hideVehicleInfo();
            showChargerDetails(charger);
        });
    } else {
        showChargerDetails(charger);
    }
}

// Keep the existing showConfirmationPopup function for backwards compatibility
function showConfirmationPopup(onConfirm) {
    const popup = document.createElement('div');
    popup.className = 'confirmation-popup';
    popup.innerHTML = `
        <p>Switching to charger details will close the vehicle details. Continue?</p>
        <button id="confirmSwitch">Yes</button>
        <button id="cancelSwitch">No</button>
    `;
    document.body.appendChild(popup);

    document.getElementById('confirmSwitch').addEventListener('click', () => {
        onConfirm();
        document.body.removeChild(popup);
    });

    document.getElementById('cancelSwitch').addEventListener('click', () => {
        document.body.removeChild(popup);
    });
}

function renderChargerList() {
    console.log('Rendering charger list');
    const searchTerm = document.getElementById('chargerSearch').value.toLowerCase();
    const chargerList = document.getElementById('chargerListItems');
    chargerList.innerHTML = '';

    console.log('Number of chargers:', chargers.length);

    if (chargers.length === 0) {
        chargerList.innerHTML = '<li>No chargers available</li>';
        return;
    }

    const filteredChargers = chargers.filter(charger => {
        return charger.name && charger.name.toLowerCase().includes(searchTerm);
    });

    console.log('Filtered chargers:', filteredChargers.length);

    if (filteredChargers.length === 0) {
        chargerList.innerHTML = '<li>No chargers found</li>';
        return;
    }

    filteredChargers.forEach(charger => {
        console.log('Rendering charger:', charger);
        const li = document.createElement('li');
        li.className = 'charger-item';
        li.innerHTML = `
            <div class="charger-name">${charger.name || 'Unnamed Charger'}</div>
            <div class="charger-details">
                <div>Status: <span class="charger-status ${getStatusClass(charger.status)}">${charger.status || 'Unknown'}</span></div>
                <div>Type: ${charger.evseId && charger.evseId.startsWith('AC') ? 'AC' : 'DC'}</div>
            </div>
        `;
        li.onclick = () => {
            console.log('Charger clicked:', charger);
            showChargerDetails(charger);
        };
        chargerList.appendChild(li);
    });
}

function showChargerDetails(charger) {
    console.error('Full charger object:', JSON.stringify(charger, null, 2));
    
    if (!charger) {
        console.error('Charger object is undefined or null');
        return;
    }

    let latitude = charger.latitude || (charger.coordinates && charger.coordinates.latitude) || (charger.location && charger.location.latitude);
    let longitude = charger.longitude || (charger.coordinates && charger.coordinates.longitude) || (charger.location && charger.location.longitude);

    if (!latitude || !longitude) {
        console.error('Invalid coordinates for charger:', charger);
        return;
    }

    latitude = parseFloat(latitude);
    longitude = parseFloat(longitude);

    if (isNaN(latitude) || isNaN(longitude)) {
        console.error('Invalid coordinates:', latitude, longitude);
        return;
    }

    const chargerForZoom = {
        coordinates: {
            latitude: latitude,
            longitude: longitude
        },
        name: charger.name || charger.title
    };

    console.error('Charger object for zooming:', chargerForZoom);

    zoomToCharger(chargerForZoom);

    const chargerDetails = document.getElementById('chargerDetails');
    const chargerDetailsPanel = document.getElementById('chargerDetailsPanel');
    
    if (chargerDetails && chargerDetailsPanel) {
        const detailsHTML = `
            <h3>${charger.name || charger.title || `Charger ${charger.id}`}</h3>
            <p><strong>ID:</strong> ${charger.id || 'N/A'}</p>
            <p><strong>UID:</strong> ${charger.uid || 'N/A'}</p>
            <p><strong>EVSE ID:</strong> ${charger.evseId || 'N/A'}</p>
            <p><strong>Status:</strong> ${charger.status !== null ? charger.status : 'Unknown'}</p>
            <p><strong>Type:</strong> ${charger.evseId && charger.evseId.startsWith('AC') ? 'AC' : 'DC'}</p>
            <p><strong>Available Connectors:</strong> ${charger.connectorsAvailable || 0} / ${charger.connectorsCount || 0}</p>
            <p><strong>Latitude:</strong> ${latitude}</p>
            <p><strong>Longitude:</strong> ${longitude}</p>
            <p><strong>Address:</strong> ${charger.address || 'N/A'}</p>
            <p><strong>Payment Info:</strong> ${charger.paymentInfo || 'N/A'}</p>
            <p><strong>Available Times:</strong> ${charger.availableTimes || 'N/A'}</p>
            <p><strong>Last Updated:</strong> ${new Date(charger.last_updated_timestamp).toLocaleString()}</p>
            <p><strong>Last Updated Date:</strong> ${charger.last_updated_date || 'N/A'}</p>
            <p><strong>Last Updated (Alternative):</strong> ${charger.lastUpdated || 'N/A'}</p>
        `;

        console.error('Charger details HTML:', detailsHTML);

        chargerDetails.innerHTML = detailsHTML;

        document.getElementById('right-sidebar').style.display = 'block';
        document.getElementById('vehicleListPanel').style.display = 'none';
        document.getElementById('mapDetailsPanel').style.display = 'none';
        document.getElementById('vehicleInfoPanel').style.display = 'none';
        document.getElementById('chargerListPanel').style.display = 'none';
        document.getElementById('chargerDetailsPanel').style.display = 'block';

    } else {
        console.error('Charger details elements not found. Make sure the HTML structure is correct.');
    }

    // Log any missing fields
    const expectedFields = ['id', 'uid', 'evseId', 'status', 'connectorsAvailable', 'connectorsCount', 'address', 'paymentInfo', 'availableTimes', 'last_updated_timestamp', 'last_updated_date', 'lastUpdated'];
    expectedFields.forEach(field => {
        if (!(field in charger)) {
            console.error(`Missing field in charger object: ${field}`);
        }
    });
}

function zoomToCharger(charger) {
    const lat = charger.coordinates.latitude;
    const lon = charger.coordinates.longitude;
    map.setView([lat, lon], 15);
}

function showClosestChargers(vehicle) {
    console.log('showClosestChargers function called');
    console.log('Showing closest chargers for vehicle:', JSON.stringify(vehicle, null, 2));
    if (!vehicle) {
        console.error('Error: Vehicle is undefined');
        updateClosestChargersDisplay('Error: Vehicle data is missing');
        return;
    }

    let vehicleData;
    if (typeof vehicle.data === 'function') {
        vehicleData = vehicle.data();
    } else {
        vehicleData = vehicle;
    }

    console.log('Vehicle data:', JSON.stringify(vehicleData, null, 2));

    let vehicleLat, vehicleLon;

    if (vehicleData.coordinates) {
        vehicleLat = parseFloat(vehicleData.coordinates.latitude);
        vehicleLon = parseFloat(vehicleData.coordinates.longitude);
    } else if (vehicleData.location) {
        vehicleLat = vehicleData.location.latitude;
        vehicleLon = vehicleData.location.longitude;
    } else if (typeof vehicleData.latitude === 'number' && typeof vehicleData.longitude === 'number') {
        vehicleLat = vehicleData.latitude;
        vehicleLon = vehicleData.longitude;
    } else {
        console.error('Error: Invalid vehicle coordinates', JSON.stringify(vehicleData, null, 2));
        updateClosestChargersDisplay('Error: Invalid vehicle location data');
        return;
    }

    console.log('Searching for chargers near:', vehicleLat, vehicleLon);
    console.log('Total chargers in database:', chargers.length);
    console.log('First 5 chargers:', JSON.stringify(chargers.slice(0, 5), null, 2));

    const closestChargers = findClosestChargers(vehicleLat, vehicleLon, 5);
    
    console.log('Closest chargers found:', closestChargers);

    if (closestChargers.length === 0) {
        console.error('Error: No chargers found within range');
        console.error('Chargers array:', JSON.stringify(chargers, null, 2));
        updateClosestChargersDisplay('No chargers found within range. Please check charger data.');
        return;
    }

    let chargersHtml = '<ul class="charger-list">';
    closestChargers.forEach(charger => {
        const statusClass = getStatusClass(charger.status);
        const chargerType = charger.evseId && charger.evseId.startsWith('AC') ? 'AC' : 'DC';
        chargersHtml += `
            <li class="charger-item ${statusClass}">
                <div class="charger-info">
                    <h3>${charger.name || `Charger ${charger.id}`}</h3>
                    <p><strong>Status:</strong> ${charger.status || 'Unknown'}</p>
                    <p><strong>Type:</strong> ${chargerType}</p>
                    <p><strong>Address:</strong> ${charger.address || 'N/A'}</p>
                    <p><strong>Available Connectors:</strong> ${charger.connectorsAvailable || 0} / ${charger.connectorsCount || 0}</p>
                    <p><strong>Distance:</strong> ${charger.distance.toFixed(2)} km</p>
                </div>
                <button class="route-button" onclick="window.createRoute('${vehicle.id}', '${charger.id}')">
                    <i class="fas fa-route"></i>
                </button>
            </li>
        `;
    });
    chargersHtml += '</ul>';

    console.log('Chargers HTML:', chargersHtml);
    updateClosestChargersDisplay(chargersHtml);

    // Add the createRoute function to the window object
    window.createRoute = (vehicleId, chargerId) => {
        const selectedVehicle = vehicle;
        const selectedCharger = closestChargers.find(c => c.id === chargerId);
        if (selectedVehicle && selectedCharger) {
            showRoute(selectedVehicle, selectedCharger);
        } else {
            console.error('Unable to create route: vehicle or charger not found');
        }
    };
}

function updateClosestChargersDisplay(content) {
    const closestChargersDiv = document.getElementById('closestChargers');
    if (!closestChargersDiv) {
        console.error('Error: Closest chargers div not found');
        return;
    }
    console.log('Updating closest chargers display with:', content);
    closestChargersDiv.innerHTML = content;
}

function findClosestChargers(lat, lon, limit = 5) {
    console.log(`Finding closest chargers to ${lat}, ${lon}`);
    console.log('Chargers array:', JSON.stringify(chargers, null, 2));
    
    if (!Array.isArray(chargers) || chargers.length === 0) {
        console.error('Error: Chargers array is empty or not an array');
        return [];
    }

    const chargersWithDistance = chargers.map(charger => {
        console.log('Processing charger:', charger);
        
        let chargerLat, chargerLon;

        if (charger.coordinates) {
            chargerLat = parseFloat(charger.coordinates.latitude);
            chargerLon = parseFloat(charger.coordinates.longitude);
        } else if (charger.location) {
            chargerLat = charger.location.latitude;
            chargerLon = charger.location.longitude;
        } else {
            console.warn('Invalid charger data:', charger);
            return null;
        }
        
        if (isNaN(chargerLat) || isNaN(chargerLon)) {
            console.warn('Invalid coordinates for charger:', charger);
            return null;
        }
        
        const distance = calculateDistance(lat, lon, chargerLat, chargerLon);
        console.log(`Calculated distance for charger ${charger.name}: ${distance} km`);
        return { ...charger, distance };
    }).filter(charger => charger !== null);

    console.log(`Found ${chargersWithDistance.length} valid chargers`);

    const sortedChargers = chargersWithDistance
        .sort((a, b) => a.distance - b.distance)
        .slice(0, limit);

    console.log('Sorted and limited chargers:', JSON.stringify(sortedChargers, null, 2));

    return sortedChargers;
}

function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in kilometers
    return distance;
}

function updateChargerMarkers(chargers) {
    chargers.forEach(charger => {
        let latitude, longitude;

        // Check if charger is a Firestore DocumentSnapshot
        const data = charger.data ? charger.data() : charger;

        if (data.coordinates) {
            latitude = parseFloat(data.coordinates.latitude);
            longitude = parseFloat(data.coordinates.longitude);
        } else if (data.location && data.location._lat && data.location._long) {
            latitude = parseFloat(data.location._lat);
            longitude = parseFloat(data.location._long);
        } else if (data.latitude && data.longitude) {
            latitude = parseFloat(data.latitude);
            longitude = parseFloat(data.longitude);
        } else {
            console.error('Invalid coordinates for charger:', data);
            return; // Skip this charger
        }

        if (!isNaN(latitude) && !isNaN(longitude)) {
            addOrUpdateMarker({
                id: charger.id || data.id,
                type: 'charger',
                latitude: latitude,
                longitude: longitude,
                name: data.name || 'Unnamed Charger',
                status: data.status || 'Unknown',
                popupContent: createPopupContent(data),
                onClick: () => handleChargerClick(data)
            });
        } else {
            console.error('Invalid latitude or longitude for charger:', data);
        }
    });
}

export { 
    setupChargerListener,
    renderChargerList,
    chargers,
    fetchChargers,
    updateChargerMarkers,
    findClosestChargers,
    cleanupChargerListener,
    handleChargerClick,
    showChargerDetails,
    zoomToCharger,
    showClosestChargers
};
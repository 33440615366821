import { addOrUpdateMarker, removeMarker, zoomToVehicle } from './map.js';
import { showClosestChargers, findClosestChargers, chargers } from './charger.js';
import { setupAutocomplete, searchAndAddStop } from './search.js';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { getDb } from './firebase-init.js';
import { showRoutePlanner } from './routePlanner.js';
import { createPopupContent } from './utils.js';

let vehicles = [];
let selectedVehicle = null;
let vehicleListener = null;

function setupVehicleListener() {
    console.log('Setting up vehicle listener');
    const db = getDb();
    const vehiclesRef = collection(db, 'vehicle');
    
    vehicleListener = onSnapshot(vehiclesRef, (snapshot) => {
        console.log('Vehicle snapshot received, number of changes:', snapshot.docChanges().length);
        snapshot.docChanges().forEach((change) => {
            if (change.type === "added" || change.type === "modified") {
                const vehicle = change.doc.data();
                vehicle.id = change.doc.id;
                console.log('Vehicle data from Firebase:', JSON.stringify(vehicle, null, 2));
                
                if (vehicle.latitude && vehicle.longitude) {
                    console.log('Adding/updating vehicle marker:', vehicle.id);
                    addOrUpdateMarker({
                        id: vehicle.id,
                        latitude: vehicle.latitude,
                        longitude: vehicle.longitude,
                        type: 'vehicle',
                        popupContent: createPopupContent(vehicle),
                        onClick: () => showVehicleDetails(vehicle)
                    });
                } else {
                    console.error('Invalid vehicle data, missing coordinates:', vehicle.id);
                }
                
                const index = vehicles.findIndex(v => v.id === vehicle.id);
                if (index !== -1) {
                    vehicles[index] = vehicle;
                } else {
                    vehicles.push(vehicle);
                }
            }
            if (change.type === "removed") {
                console.log('Removing vehicle:', change.doc.id);
                removeMarker(change.doc.id);
                vehicles = vehicles.filter(v => v.id !== change.doc.id);
            }
        });
        console.log('Total vehicles after update:', vehicles.length);
        renderVehicleList();
    });
}

function renderVehicleList() {
    console.log('Rendering vehicle list, total vehicles:', vehicles.length);
    const vehicleListItems = document.getElementById('vehicleListItems');
    if (!vehicleListItems) {
        console.error('Vehicle list element not found');
        return;
    }
    vehicleListItems.innerHTML = '';
    vehicles.forEach(vehicle => {
        const li = document.createElement('li');
        li.className = 'vehicle-item'; // Add this line to apply styling
        
        // Create a div for the vehicle name
        const nameDiv = document.createElement('div');
        nameDiv.className = 'vehicle-name';
        nameDiv.textContent = vehicle.name || `Vehicle ${vehicle.id}`;
        
        // Create a div for the vehicle status (you can customize this based on your data)
        const statusDiv = document.createElement('div');
        statusDiv.className = 'vehicle-status';
        statusDiv.textContent = vehicle.status || 'Unknown';
        
        // Append name and status to the list item
        li.appendChild(nameDiv);
        li.appendChild(statusDiv);
        
        li.addEventListener('click', () => showVehicleDetails(vehicle));
        vehicleListItems.appendChild(li);
    });
    console.log('Vehicle list rendered');
}

function showVehicleDetails(vehicle) {
    console.log('showVehicleDetails called with vehicle:', JSON.stringify(vehicle, null, 2));

    if (!vehicle) {
        console.error('Vehicle is null or undefined');
        return;
    }

    let data;
    if (typeof vehicle.data === 'function') {
        data = vehicle.data();
        console.log('Vehicle data from function:', JSON.stringify(data, null, 2));
    } else {
        data = vehicle; // Use the vehicle object directly if it's not a Firestore document
        console.log('Vehicle data from object:', JSON.stringify(data, null, 2));
    }

    if (!data) {
        console.error('Unable to retrieve vehicle data');
        return;
    }

    console.log('Vehicle name:', data.name);
    console.log('Vehicle ID:', data.id);

    const lastUpdated = data.last_updated_timestamp ? new Date(data.last_updated_timestamp).toLocaleString() : 'N/A';
    
    // Hard-coded driver details (for now)
    const driverName = "Johann Pickard";
    const driverPhone = "0607220865";

    const vehicleDetails = document.getElementById('vehicleDetails');
    vehicleDetails.innerHTML = `
        <h3>${data.name || `Vehicle ${data.id}`}</h3>
        <p><strong>ID:</strong> ${data.id}</p>
        <p><strong>Name:</strong> ${data.name || 'N/A'}</p>
        <p><strong>Speed:</strong> ${data.speed || 0} km/h</p>
        <p><strong>Latitude:</strong> ${data.latitude ? data.latitude.toFixed(6) : 'N/A'}</p>
        <p><strong>Longitude:</strong> ${data.longitude ? data.longitude.toFixed(6) : 'N/A'}</p>
        <p><strong>Last Updated:</strong> ${data.last_updated_date || lastUpdated}</p>
        <p><strong>Last Updated Timestamp:</strong> ${data.last_updated_timestamp || 'N/A'}</p>
        <p><strong>Driver:</strong> ${driverName}</p>
        <p>
            <strong>Contact:</strong> ${driverPhone}
            <a href="tel:${driverPhone}" class="icon-button" title="Call Driver">
                <i class="fas fa-phone"></i>
            </a>
            <a href="https://wa.me/${driverPhone}" target="_blank" class="icon-button" title="WhatsApp Driver">
                <i class="fab fa-whatsapp"></i>
            </a>
        </p>
        <button id="routePlannerBtn" class="icon-button" title="Route Planner">
            <i class="fas fa-route"></i> Route Planner
        </button>
        <h4>Closest Chargers <button id="updateClosestChargersBtn" class="icon-button" title="Update Closest Chargers"><i class="fas fa-sync-alt"></i></button></h4>
        <div id="closestChargers">Loading closest chargers...</div>
        <h4>Add Stop</h4>
        <div class="add-stop-container">
            <input type="text" id="stopSearch" placeholder="Search for a stop">
            <button id="addStop" class="icon-button" title="Add Stop">
                <i class="fas fa-plus"></i>
            </button>
        </div>
        <ul id="stopsList"></ul>
    `;
    document.getElementById('right-sidebar').style.display = 'block';
    document.getElementById('vehicleListPanel').style.display = 'none';
    document.getElementById('mapDetailsPanel').style.display = 'none';
    document.getElementById('chargerListPanel').style.display = 'none';
    document.getElementById('vehicleInfoPanel').style.display = 'block';
    document.getElementById('chargerDetailsPanel').style.display = 'none';

    zoomToVehicle(vehicle);
    
    try {
        showClosestChargers(vehicle);
    } catch (error) {
        console.error('Error showing closest chargers:', error);
        const closestChargersDiv = document.getElementById('closestChargers');
        if (closestChargersDiv) {
            closestChargersDiv.innerHTML = '<p>Error loading closest chargers.</p>';
        }
    }

    document.getElementById('addStop').addEventListener('click', function() {
        const searchTerm = document.getElementById('stopSearch').value;
        if (searchTerm) {
            searchAndAddStop(searchTerm, vehicle);
        }
    });

    document.getElementById('stopSearch').addEventListener('keyup', function(event) {
        if (event.key === 'Enter') {
            const searchTerm = this.value;
            if (searchTerm) {
                searchAndAddStop(searchTerm, vehicle);
            }
        }
    });

    setupAutocomplete(document.getElementById('stopSearch'));

    selectedVehicle = vehicle;

    // Show closest chargers
    showClosestChargersForVehicle(vehicle);

    document.getElementById('updateClosestChargersBtn').addEventListener('click', () => {
        console.log('Closest chargers button clicked');
        updateClosestChargers(vehicle);
    });

    // Initial load of closest chargers
    updateClosestChargers(vehicle);

    // Add event listener for the route planner button
    document.getElementById('routePlannerBtn').addEventListener('click', () => {
        console.log('Route planner button clicked');
        showRoutePlanner(vehicle);
    });
}

function showClosestChargersForVehicle(vehicle) {
    console.log('Showing closest chargers for vehicle:', vehicle);
    if (!vehicle) {
        console.error('Vehicle is undefined');
        return;
    }

    let vehicleData;
    if (typeof vehicle.data === 'function') {
        vehicleData = vehicle.data();
    } else {
        vehicleData = vehicle;
    }

    console.log('Vehicle data:', vehicleData);

    if (!vehicleData || typeof vehicleData.latitude !== 'number' || typeof vehicleData.longitude !== 'number') {
        console.error('Invalid vehicle coordinates');
        return;
    }

    const vehicleLat = vehicleData.latitude;
    const vehicleLon = vehicleData.longitude;

    const closestChargers = findClosestChargers(vehicleLat, vehicleLon, 5);
    
    const closestChargersDiv = document.getElementById('closestChargers');
    if (!closestChargersDiv) {
        console.error('Closest chargers div not found');
        return;
    }

    if (closestChargers.length === 0) {
        closestChargersDiv.innerHTML = '<p>No chargers found.</p>';
        return;
    }

    let chargersHtml = '<ul>';
    closestChargers.forEach(charger => {
        chargersHtml += `
            <li>
                <strong>${charger.name || `Charger ${charger.id}`}</strong><br>
                Distance: ${charger.distance.toFixed(2)} km<br>
                Status: ${charger.status || 'Unknown'}
            </li>
        `;
    });
    chargersHtml += '</ul>';

    closestChargersDiv.innerHTML = chargersHtml;
}

function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in kilometers
    return distance;
}

function showVehicleList() {
    const rightSidebar = document.getElementById('right-sidebar');
    const vehicleListPanel = document.getElementById('vehicleListPanel');
    const mapDetailsPanel = document.getElementById('mapDetailsPanel');
    const vehicleInfoPanel = document.getElementById('vehicleInfoPanel');
    const chargerListPanel = document.getElementById('chargerListPanel');
    const chargerDetailsPanel = document.getElementById('chargerDetailsPanel');

    // Show the entire sidebar
    rightSidebar.style.display = 'block';

    // Show the vehicle list panel and hide others
    vehicleListPanel.style.display = 'block';
    mapDetailsPanel.style.display = 'none';
    vehicleInfoPanel.style.display = 'none';
    chargerListPanel.style.display = 'none';
    chargerDetailsPanel.style.display = 'none';

    renderVehicleList();
}

function addStopToVehicle(stop, vehicle) {
    const stopsList = document.getElementById('stopsList');
    const li = document.createElement('li');
    li.textContent = `${stop.display_name} (${stop.lat}, ${stop.lon})`;
    stopsList.appendChild(li);

    // Here you would typically update the vehicle's planned stops in your database
    // For this example, we'll just log it
    console.log(`Added stop ${stop.display_name} to vehicle ${vehicle.id}`);
}

function getSelectedVehicle() {
    console.log('Getting selected vehicle:', selectedVehicle);
    return selectedVehicle;
}

function updateClosestChargers(vehicle) {
    console.log('Updating closest chargers for vehicle:', vehicle);
    const closestChargersDiv = document.getElementById('closestChargers');
    closestChargersDiv.innerHTML = 'Searching for closest chargers...';

    try {
        showClosestChargers(vehicle);
    } catch (error) {
        console.error('Error updating closest chargers:', error);
        console.error('Vehicle data:', JSON.stringify(vehicle, null, 2));
        closestChargersDiv.innerHTML = `<p>Error: ${error.message}</p>`;
    }
}

// Add this new function to clean up the vehicle listener
function cleanupVehicleListener() {
    if (vehicleListener) {
        vehicleListener();
        vehicleListener = null;
    }
    // Reset any vehicle-related state
    vehicles = [];
    selectedVehicle = null;
}

export { 
    setupVehicleListener, 
    showVehicleDetails, 
    renderVehicleList, 
    addStopToVehicle,
    vehicles,
    showVehicleList,
    getSelectedVehicle,
    updateClosestChargers,
    cleanupVehicleListener 
};


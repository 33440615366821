import { map } from './map.js';

async function optimizeRoute(start, stops, end) {
    const allPoints = [start, ...stops, end];
    const optimizedRoute = [start];
    const remainingStops = new Set(stops);

    while (remainingStops.size > 0) {
        let bestStop = null;
        let shortestTime = Infinity;

        for (const stop of remainingStops) {
            const lastPoint = optimizedRoute[optimizedRoute.length - 1];
            const time = await getRouteDuration(lastPoint, stop);

            if (time < shortestTime) {
                shortestTime = time;
                bestStop = stop;
            }
        }

        optimizedRoute.push(bestStop);
        remainingStops.delete(bestStop);
    }

    optimizedRoute.push(end);
    return optimizedRoute;
}

async function getRouteDuration(start, end) {
    const url = `https://router.project-osrm.org/route/v1/driving/${start.lon},${start.lat};${end.lon},${end.lat}?overview=false`;
    
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data.routes[0].duration;
    } catch (error) {
        console.error('Error fetching route duration:', error);
        return Infinity;
    }
}

async function drawOptimizedRoute(optimizedStops) {
    const waypoints = optimizedStops.map(stop => L.latLng(stop.lat, stop.lon));
    
    if (window.currentRoute) {
        map.removeControl(window.currentRoute);
    }

    window.currentRoute = L.Routing.control({
        waypoints: waypoints,
        routeWhileDragging: true,
        show: false,
        addWaypoints: false,
        draggableWaypoints: false,
        fitSelectedRoutes: true,
        showAlternatives: false
    }).addTo(map);

    return new Promise((resolve) => {
        window.currentRoute.on('routesfound', function(e) {
            const routes = e.routes;
            const summary = routes[0].summary;
            resolve({
                distance: summary.totalDistance / 1000, // Convert to km
                time: Math.round(summary.totalTime / 60) // Convert to minutes
            });
        });
    });
}

export { optimizeRoute, drawOptimizedRoute };
import { renderVehicleList, showVehicleList } from './vehicle.js';
import { renderChargerList } from './charger.js';
import { updateMarkerDisplay } from './map.js';

function setupSidebar() {
    const rightSidebar = document.getElementById('right-sidebar');
    const vehicleListPanel = document.getElementById('vehicleListPanel');
    const mapDetailsPanel = document.getElementById('mapDetailsPanel');
    const vehicleInfoPanel = document.getElementById('vehicleInfoPanel');
    const chargerListPanel = document.getElementById('chargerListPanel');

    const setupButton = (id, clickHandler) => {
        const button = document.getElementById(id);
        if (button) {
            button.addEventListener('click', clickHandler);
        }
    };

    setupButton('vehicleListButton', showVehicleList);
    setupButton('mapDetailsButton', () => {
        if (rightSidebar) rightSidebar.style.display = 'block';
        if (vehicleListPanel) vehicleListPanel.style.display = 'none';
        if (mapDetailsPanel) mapDetailsPanel.style.display = 'block';
        if (vehicleInfoPanel) vehicleInfoPanel.style.display = 'none';
        if (chargerListPanel) chargerListPanel.style.display = 'none';
    });
    setupButton('chargerButton', () => {
        if (rightSidebar) rightSidebar.style.display = 'block';
        if (vehicleListPanel) vehicleListPanel.style.display = 'none';
        if (mapDetailsPanel) mapDetailsPanel.style.display = 'none';
        if (vehicleInfoPanel) vehicleInfoPanel.style.display = 'none';
        if (chargerListPanel) {
            chargerListPanel.style.display = 'block';
            renderChargerList();
        }
    });
    setupButton('hideVehicleList', hideVehicleList);
    setupButton('hideVehicleInfo', hideVehicleInfo);

    const setupInput = (id, inputHandler) => {
        const input = document.getElementById(id);
        if (input) {
            input.addEventListener('input', inputHandler);
        }
    };

    setupInput('vehicleSearch', renderVehicleList);
    setupInput('chargerSearch', renderChargerList);
}

function recreateSidebarElements() {
    const rightSidebar = document.getElementById('right-sidebar');
    if (rightSidebar) {
        rightSidebar.innerHTML = `
            <div id="vehicleListPanel" class="panel">
                <button id="hideVehicleList" class="hide-panel-info">
                    <i class="fas fa-times"></i>
                </button>
                <h2>Vehicle List</h2>
                <input type="text" id="vehicleSearch" placeholder="Search vehicles...">
                <ul id="vehicleListItems"></ul>
            </div>
            <div id="mapDetailsPanel" class="panel" style="display:none;">
                <h2>Map Details</h2>
                <div id="filterOptions">
                    <label><input type="checkbox" id="showVehicles" checked> Show Vehicles</label><br>
                    <label><input type="checkbox" id="showSpeed" checked> Show Speed</label><br>
                    <label><input type="checkbox" id="showChargers" checked> Show Chargers</label>
                </div>
            </div>
            <div id="vehicleInfoPanel" class="panel" style="display:none;">
                <button id="hideVehicleInfo" class="hide-panel-info">
                    <i class="fas fa-times"></i>
                </button>
                <div id="vehicleDetails"></div>
            </div>
            <div id="chargerListPanel" class="panel" style="display:none;">
                <h2>Charger List</h2>
                <input type="text" id="chargerSearch" placeholder="Search chargers...">
                <ul id="chargerListItems"></ul>
            </div>
            <div id="chargerDetailsPanel" class="panel" style="display:none;">
                <h2>Charger Details</h2>
                <div id="chargerDetails"></div>
            </div>
            <div id="userProfilePanel" class="panel" style="display:none;">
                <!-- User profile content will be populated dynamically -->
            </div>
        `;
    }
}

function hideVehicleList() {
    const rightSidebar = document.getElementById('right-sidebar');
    const vehicleListPanel = document.getElementById('vehicleListPanel');
    
    // Hide the entire sidebar
    rightSidebar.style.display = 'none';
    
    // Also hide the vehicle list panel (keeping existing functionality)
    vehicleListPanel.style.display = 'none';
}

function hideVehicleInfo() {
    document.getElementById('vehicleInfoPanel').style.display = 'none';
}

function setupMapDetailsListeners() {
    const showVehicles = document.getElementById('showVehicles');
    const showSpeed = document.getElementById('showSpeed');
    const showChargers = document.getElementById('showChargers');

    if (showVehicles) {
        showVehicles.addEventListener('change', updateMarkerDisplay);
    }
    if (showSpeed) {
        showSpeed.addEventListener('change', updateMarkerDisplay);
    }
    if (showChargers) {
        showChargers.addEventListener('change', updateMarkerDisplay);
    }
}

export { 
    setupSidebar, 
    hideVehicleInfo, 
    hideVehicleList, 
    setupMapDetailsListeners,
    recreateSidebarElements 
};
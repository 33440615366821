import { map } from './map.js';
import { setupAutocomplete, searchAndAddStop } from './search.js';
import { estimateSOCRequirement } from './utils.js';
import { sendRouteToDriver } from './communication.js'; // Add this import
import { optimizeRoute, drawOptimizedRoute } from './routeOptimizer.js'; // Add this import

let currentRoute = null;

function updateRouteAndSOC(vehicle, charger) {
    showRoute(vehicle, charger);
}

function showRoute(vehicle, charger) {
    console.log('showRoute called with:', { vehicle, charger });
    
    // Ensure we have valid data for both vehicle and charger
    if (!vehicle || !charger) {
        console.error('Invalid vehicle or charger data');
        return;
    }

    let vehicleLatLng, chargerLatLng;

    // Handle vehicle data
    if (typeof vehicle.data === 'function') {
        const vehicleData = vehicle.data();
        vehicleLatLng = L.latLng(vehicleData.latitude, vehicleData.longitude);
        console.log('Vehicle data from function:', vehicleData);
    } else if (vehicle.latitude && vehicle.longitude) {
        vehicleLatLng = L.latLng(vehicle.latitude, vehicle.longitude);
        console.log('Vehicle data from object:', vehicle);
    } else if (vehicle.coordinates) {
        vehicleLatLng = L.latLng(vehicle.coordinates.latitude, vehicle.coordinates.longitude);
        console.log('Vehicle data from coordinates object:', vehicle);
    } else {
        console.error('Invalid vehicle data structure:', vehicle);
        return;
    }

    // Handle charger data
    if (typeof charger.data === 'function') {
        const chargerData = charger.data();
        chargerLatLng = L.latLng(chargerData.latitude, chargerData.longitude);
        console.log('Charger data from function:', chargerData);
    } else if (charger.latitude && charger.longitude) {
        chargerLatLng = L.latLng(charger.latitude, charger.longitude);
        console.log('Charger data from object:', charger);
    } else if (charger.coordinates) {
        chargerLatLng = L.latLng(charger.coordinates.latitude, charger.coordinates.longitude);
        console.log('Charger data from coordinates object:', charger);
    } else if (charger.location && charger.location._lat && charger.location._long) {
        chargerLatLng = L.latLng(charger.location._lat, charger.location._long);
        console.log('Charger data from location object:', charger);
    } else {
        console.error('Invalid charger data structure:', charger);
        return;
    }

    console.log('Vehicle LatLng:', vehicleLatLng);
    console.log('Charger LatLng:', chargerLatLng);

    const stops = Array.from(document.getElementById('routeStopsList')?.children || []).map(li => {
        const [lat, lon] = li.textContent.split(',').map(parseFloat);
        return L.latLng(lat, lon);
    });

    const waypoints = [vehicleLatLng, ...stops, chargerLatLng];

    if (currentRoute) {
        map.removeControl(currentRoute);
    }

    currentRoute = L.Routing.control({
        waypoints: waypoints,
        router: L.Routing.osrmv1({
            serviceUrl: 'https://router.project-osrm.org/route/v1'
        }),
        routeWhileDragging: false,
        showAlternatives: true,
        altLineOptions: {
            styles: [
                {color: 'black', opacity: 0.15, weight: 9},
                {color: 'white', opacity: 0.8, weight: 6},
                {color: 'blue', opacity: 0.5, weight: 2}
            ]
        },
        collapsible: true,
        show: false
    }).addTo(map);

    console.log('Route waypoints:', currentRoute.getWaypoints());

    currentRoute.on('routesfound', function(e) {
        console.log('Routes found', e);
        var routes = e.routes;
        var summary = routes[0].summary;
        // time is in seconds, distance in meters
        const distance = (summary.totalDistance / 1000).toFixed(2);
        const time = Math.round(summary.totalTime / 60);
        const { socPercentage, energyRequired } = estimateSOCRequirement(parseFloat(distance), vehicle);
        
        const routeInfoContent = document.getElementById('routeInfoContent');
        routeInfoContent.innerHTML = `
            <div class="route-info-buttons">
                <button id="toggleDirections" class="icon-button" title="Toggle Detailed Directions">
                    <i class="fas fa-directions"></i>
                </button>
                <button id="sendRouteToDriver" class="icon-button" title="Send Route to Driver">
                    <i class="fas fa-share"></i>
                </button>
                <button id="optimizeRoute" class="icon-button" title="Optimize for Shortest Distance">
                    <i class="fas fa-route"></i>
                </button>
                <button id="clearRoute" class="icon-button" title="Clear Route">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <h3>Route to ${charger.name}</h3>
            <p><strong>Distance:</strong> ${distance} km</p>
            <p><strong>Estimated time:</strong> ${time} minutes</p>
            <p><strong>Estimated energy required:</strong> ${energyRequired} kWh</p>
            <p><strong>Estimated SOC required:</strong> ${socPercentage}%</p>
            <p><strong>Current vehicle SOC:</strong> ${vehicle.soc || 'N/A'}%</p>
            <div class="add-stop-container">
                <input type="text" id="stopSearch" placeholder="Search for a stop">
                <button id="addStop" class="icon-button" title="Add Stop">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
            <ul id="stopsList"></ul>
        `;
        
        document.getElementById('routeInfoPopup').classList.add('active');

        // Add event listener for toggling directions
        document.getElementById('toggleDirections').addEventListener('click', function() {
            const container = document.querySelector('.leaflet-routing-container');
            if (container.classList.contains('leaflet-routing-container-hide')) {
                container.classList.remove('leaflet-routing-container-hide');
            } else {
                container.classList.add('leaflet-routing-container-hide');
            }
        });

        // Add event listener for clearing the route
        document.getElementById('clearRoute').addEventListener('click', clearCurrentRoute);

        // Add event listener for adding a stop
        document.getElementById('addStop').addEventListener('click', function() {
            const searchTerm = document.getElementById('stopSearch').value;
            if (searchTerm) {
                searchAndAddStop(searchTerm, vehicle, charger);
            }
        });

        // Add event listener for Enter key in search input
        document.getElementById('stopSearch').addEventListener('keyup', function(event) {
            if (event.key === 'Enter') {
                const searchTerm = this.value;
                if (searchTerm) {
                    searchAndAddStop(searchTerm, vehicle, charger);
                }
            }
        });

        // Add event listener for sending route to driver
        document.getElementById('sendRouteToDriver').addEventListener('click', function() {
            const driverPhone = "0607220865"; // Hard-coded for now
            const routeInfo = {
                distance: distance,
                time: time,
                energyRequired: energyRequired,
                socRequired: socPercentage,
                destination: charger.name,
                currentSoc: vehicle.soc, // Add this line
                chargerType: charger.evseId.startsWith('AC') ? 'AC' : 'DC', // Add this line
                chargerStatus: charger.status, // Add this line
                availableConnectors: `${charger.connectorsAvailable} / ${charger.connectorsCount}` // Add this line
            };
            sendRouteToDriver(driverPhone, routeInfo);
        });

        // Add event listener for optimizing the route
        document.getElementById('optimizeRoute').addEventListener('click', function() {
            optimizeCurrentRoute(vehicle, charger);
        });

        // Set up autocomplete for stop search
        setupAutocomplete(document.getElementById('stopSearch'));
    });
}

function clearCurrentRoute() {
    if (currentRoute) {
        map.removeControl(currentRoute);
        currentRoute = null;
    }
    document.getElementById('routeInfoPopup').classList.remove('active');
    const directionsContainer = document.querySelector('.leaflet-routing-container');
    if (directionsContainer) {
        directionsContainer.classList.add('leaflet-routing-container-hide');
    }
}

async function optimizeCurrentRoute(vehicle, charger) {
    const stops = Array.from(document.getElementById('stopsList').children).map(li => {
        const [lat, lon] = li.dataset.coordinates.split(',').map(parseFloat);
        return { lat, lon };
    });

    const start = { lat: vehicle.latitude, lon: vehicle.longitude };
    const end = { lat: charger.coordinates.latitude, lon: charger.coordinates.longitude };

    const optimizedStops = await optimizeRoute(start, stops, end);

    // Update the stops list in the UI
    const stopsList = document.getElementById('stopsList');
    stopsList.innerHTML = '';
    optimizedStops.slice(1, -1).forEach((stop, index) => {
        const li = document.createElement('li');
        li.textContent = `Stop ${index + 1}: ${stop.lat.toFixed(6)}, ${stop.lon.toFixed(6)}`;
        li.dataset.coordinates = `${stop.lat},${stop.lon}`;
        stopsList.appendChild(li);
    });

    // Draw the optimized route on the map
    const routeSummary = await drawOptimizedRoute(optimizedStops);

    // Update route information
    updateRouteInfo(routeSummary, vehicle, charger);
}

function updateRouteInfo(routeSummary, vehicle, charger) {
    const { distance, time } = routeSummary;
    const energyRequired = estimateSOCRequirement(distance, vehicle.efficiency);
    const socPercentage = (energyRequired / vehicle.batteryCapacity) * 100;

    const routeInfoContent = document.getElementById('routeInfoContent');
    routeInfoContent.innerHTML = `
        <h3>Route Summary</h3>
        <p>Total Distance: ${distance.toFixed(2)} km</p>
        <p>Estimated Time: ${time} minutes</p>
        <p>Estimated Energy Required: ${energyRequired.toFixed(2)} kWh</p>
        <p>Estimated SOC Required: ${socPercentage.toFixed(2)}%</p>
    `;

    // Show the route info popup
    document.getElementById('routeInfoPopup').classList.add('active');
}

export { 
    updateRouteAndSOC, 
    clearCurrentRoute,
    showRoute,
    optimizeCurrentRoute
};